<template>
  <div>
    <Row class="p-t-10">
      <i-col span="24">
          <p class="remark p-b-5">* 分线路导出当前全部在刊详情统计</p>
          <i-button size="small" type="success" class="m-l-5" @click.native="initPage">导出当前在刊详情统计</i-button>
      </i-col>
    </Row>

    <Modal v-model="modalShow" width="600" title="导出当前在刊详情统计" footer-hide>
        <Row class="p-b-5">
            <i-col span="24">
                <label class="title">线路</label>
                <div v-for="(line,index) in assetList" :key="index"
                    class="m-b-5 m-r-5"  @click="changeCheckLine(line.id)"
                    :class="chooseAssetId===line.id?'workplatform-radio-button-active':'workplatform-radio-button'">{{line.name}}</div>
            </i-col>
        </Row>

        <Row class="p-b-5">
            <i-col span="24">
                <label class="title">操作</label>
                <Button type="success" size="small" @click="handleSubmit">生成在刊统计照片集</Button>
                <Button type="success" size="small" class="m-l-10" @click="downloadExcel">在线下载统计EXCEL</Button>
            </i-col>
        </Row>

      <label class="title">历史在刊统计照片集清单</label>
      <Table  stripe class="m-t-5" size="small" :columns="fileColums" :data="fileList"></Table>
    </Modal>
  </div>
</template>

<script>
import { getPublisherAssetList } from '@/api/rim/asset'
import { getPptReportList, downloadAttch } from '@/api/msp/msppreport'
import { downloadFileRequest } from '@/utils/download'

export default {
  data () {
    return {
      modalShow: false,
      assetList: [], // 所有可用线路
      chooseAssetId: 0,
      fileColums: [
        { title: '生成日期', key: 'createTime' },
        {
          title: '进度',
          width: 100,
          render: (h, params) => {
            if (params.row.status === -1) { // 生成失败
              return h('Icon', { props: { type: 'md-close-circle', color: '#ed4014', size: '12' } })
            } else if (params.row.status === 1 || params.row.status === 2) { // 正在处理
              const processName = 'exportOrderExcel' + params.row.id
              this.registerProcess(processName)

              return h('div', [
                h('span', { attrs: { class: processName } }, '1'),
                h('span', '%')
              ])
            } else {
              return h('Icon', { props: { type: 'md-checkmark', color: '#44BD32', size: '16' } })
            }
          }
        },
        {
          title: '操作',
          width: 100,
          render: (h, params) => {
            if (params.row.status === 3) {
              return h('a',
                {
                  on: {
                    click: () => {
                      this.downloadFile(params.row.id)
                    }
                  }

                }, '下载'
              )
            }
          }
        }
      ],
      fileList: [],
      total: 0,
      loadFileEnd: false
    }
  },
  methods: {
    /**
     * 初始化获取导出Excel配置
     */
    initPage () {
      this.modalShow = true

      getPublisherAssetList().then(res => {
        this.assetList = res
        this.chooseAssetId = res[0].id

        this.loadFileList()
      })
    },
    loadFileList () {
      const postData = {
        taskId: this.chooseAssetId,
        fileType: 3
      }

      getPptReportList(postData).then(res => {
        this.fileList = res
      })
    },
    changeCheckLine (assetId) {
      this.chooseAssetId = assetId
      this.loadFileList()
    },
    handleSubmit () {
      downloadAttch({ assetId: this.chooseAssetId }).then(() => {
        this.loadFileList()
      })
    },
    downloadExcel () {
      const assetName = this.assetList.find(x => x.id === this.chooseAssetId).name

      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-msp/v2/msppreport/downloadassetpublishingexcel', { assetId: this.chooseAssetId }, assetName + '在刊统计.xlsx')
    },
    downloadFile (fileId) {
      window.open(process.env.VUE_APP_API_URL_V2 + '/ooh-msp/v2/open/msp/downloadpic?reportFileId=' + fileId)
    },
    registerProcess (name) {
      if (this.loadFileEnd) { return }

      const processControl = window.document.getElementsByClassName(name)

      if (processControl.length === 0) {
        setTimeout(() => {
          this.registerProcess(name)
        }, 1000)
      } else if (processControl[0].innerText !== '99') {
        processControl[0].innerText = parseInt(processControl[0].innerText) + 1
        setTimeout(() => {
          this.registerProcess(name)
        }, 1000)
      } else {
        this.loadFileEnd = true
        this.loadFileList()
      }
    }
  }
}
</script>
